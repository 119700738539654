<template>
  <ElDialog
    id="import-appfolio-dialog"
    custom-class="center-dialog-vertically"
    :class="{'short-dialog':uiState.flow === uiFlow.ERROR}"
    :center="true"
    :show-close="uiState.flow === uiFlow.ERROR"
    visible
    width="500px"
    :fullscreen="$viewport.lt.md"
    :destroy-on-close="true"
    @close="close"
  >
    <div slot="title">
      <h2 class="text-black font-21 mb-0">
        {{ uiState.flow === uiFlow.ENABLED ? 'Your AppFolio Integration is Enabled' : 'Sync your AppFolio data with Showdigs' }}
      </h2>
    </div>
    <div class="row d-flex justify-content-center mt-1">
      <div
        v-if="uiState.flow === uiFlow.INIT"
        class="col-12 pt-0"
      >
        <ElAlert
          type="info"
          :closable="false"
          class="p-3 line-height-1.5"
          tag-class="tagline-info"
          icon-class="sdicon-info-circle"
        >
          <ul class="pl-3">
            <li class="my-3">
              AppFolio <strong>Properties</strong> and <strong>Units</strong> will be imported and synced.
            </li>
            <li class="my-3">
              AppFolio <strong>Guest Cards</strong> will be synced into Showdigs <strong>Inquiries</strong> and <strong>Prospects</strong>.
            </li>
            <li class="my-3">
              Showdigs <strong>Completed Tours</strong> will be synced into AppFolio <strong>Showings</strong>.
            </li>
            <li class="my-3">
              Showdigs <strong>Condition Reports</strong> PDFs will be synced into AppFolio <strong>Property Attachments</strong>.
            </li>
            <li class="my-3">
              Showdigs will prefill equivalent AppFolio fields to save you data entry.
            </li>
          </ul>
        </ElAlert>
      </div>
      <div
        v-if="uiState.flow === uiFlow.REVIEW"
        class="col-12 pt-0 text-center"
      >
        <div
          v-if="counts === null"
          class="d-flex flex-column justify-content-center  align-items-center mt-5"
        >
          <div class="mb-5 text-primary">
            Fetching data from AppFolio, this might take a few seconds...
          </div>
          <SdSpinner />
        </div>
        <div
          v-else
          class="text-center"
        >
          <div class="bg-primary rounded-circle d-inline-flex justify-content-center align-items-center p-4 m-5">
            <i class="sdicon-swap text-white font-32" />
          </div>
          <div
            v-if="counts.create"
            class="my-3"
          >
            <i class="sdicon-home-plus text-success font-21" />
            <strong>{{ counts.create }} New Properties</strong> will be imported and synced together with their units.
          </div>
          <div
            v-if="counts.update"
            class="my-3"
          >
            <i class="sdicon-swap text-primary font-21" />
            {{ counts.update }} <strong>Existing Properties</strong> will be updated together with their units.
          </div>
          <div
            v-if="counts.archive"
            class="my-3"
          >
            <i class="sdicon-trash text-warning font-21" />
            {{ counts.archive }} <strong>Existing Properties</strong> will be archived together with their units.
          </div>

          <ElAlert
            v-if="counts.properties > 500"
            type="warning"
            show-icon
            :closable="false"
            class="p-3"
          >
            We've noticed a large number of properties in your AppFolio account.
            Please make sure you only expose Showdigs with your relevant data. If there's a mistake,
            please adjust your AppFolio Stack Marketplace settings and try again.
          </ElAlert>
          <p class="mt-5 mb-0">
            Click Enable Sync to Import and keep syncing your data from AppFolio
          </p>
        </div>
      </div>
      <div
        v-if="uiState.flow === uiFlow.ENABLED"
        class="col-12 pt-0 text-center"
      >
        <div class="my-5">
          <img
            src="@/assets/circle-check.svg"
            class="review-success-icon mb-5"
          >
          <p class="mt-5">
            We've started syncing your AppFolio data. you'll be emailed when the initial sync is complete.
          </p>
        </div>
      </div>
      <div
        v-if="uiState.flow===uiFlow.ERROR"
        class="text-center position-relative"
      >
        <h2>An Error Occurred</h2>
        <p>
          We couldn't sync your AppFolio data.
          Please verify that you've correctly activated the integration in AppFolio Stack Marketplace and try again or contact Showdigs support.
        </p>
      </div>
    </div>
    <span
      v-if="uiState.flow !== uiFlow.ERROR"
      slot="footer"
      class="row justify-content-center"
    >
      <div
        class="col-md-4"
        :class="uiState.flow === uiFlow.ENABLED ? 'col-12':'col-6'"
      >
        <ElButton
          class="w-100"
          size="medium"
          @click="close"
        >
          {{ uiState.flow === uiFlow.ENABLED ? 'Close' : 'Cancel' }}
        </ElButton>
      </div>
      <div
        v-if="uiState.flow !== uiFlow.ENABLED"
        class="col-6 col-md-4"
      >
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="uiState.is_loading"
          @click="next"
        >
          <span v-if="uiFlow.INIT === uiState.flow">Review Data</span>
          <span v-if="uiFlow.REVIEW === uiState.flow">Enable Sync</span>
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import SdSpinner from '@/components/common/SdSpinner.vue';

const uiFlow = {
  INIT: 'init',
  REVIEW: 'review',
  ENABLED: 'enabled',
  ERROR: 'error',
};
export default {
  name: 'AppfolioSyncDialog',
  components: { SdSpinner },
  props: {
  },
  setup(props, context) {
    const store = context.root.$store;
    const uiState = reactive({
      flow: uiFlow.INIT,
      is_loading: false,
    });
    const counts = ref(null);
    const newBusinessSource = ref(null);
    return {
      uiFlow,
      uiState,
      counts,
      close,
      next,
    };

    async function review() {
      uiState.is_loading = true;
      const response = await store.dispatch('BusinessSource/review');
      uiState.is_loading = false;
      counts.value = response;
    }

    async function enable() {
      try {
        newBusinessSource.value = await store.dispatch('BusinessSource/update', { auto_sync: true });
        uiState.flow = uiFlow.ENABLED;
      } catch (e) {
        uiState.flow = uiFlow.ERROR;
        uiState.is_loading = false;
      }
    }
    function next() {
      if (uiState.flow === uiFlow.INIT) {
        uiState.flow = uiFlow.REVIEW;
        review();
        return;
      }
      if (uiState.flow === uiFlow.REVIEW) {
        enable();
      }
    }

    function close() {
      if (newBusinessSource.value && uiState.flow !== uiFlow.ENABLED) {
        store.commit('BusinessSource/set', newBusinessSource.value);
      } else {
        context.emit('close');
      }
    }
  },
};
</script>
<style lang="scss">
    #import-appfolio-dialog {
        .el-dialog {
            min-height: 362px;
        }
      &.short-dialog {
        .el-dialog {
          min-height: 187px;
        }
      }
        .loader-wrapper {
            position: absolute;
            top:0;
            left: 0;
            background: $white;
            width: 100%;
            height: 100%;
            z-index: 1000;
            border-radius: $app-border-radius;
        }

        .review-success-icon {
            width: 3.5rem;
            height: 3.5rem;
        }
        .listings{
            height: 12rem;
            overflow-y:auto;
            overflow-x: hidden;
            .listing-item {
                color: gray-color('dark');
                font-size: $--font-size-base;
                padding: 0.5rem;
                border-bottom: 1px solid gray-color('light');
                .active-icon {
                    width: $--font-size-base;
                    height: $--font-size-base;
                    display: inline-block;
                    i {
                        display: block;
                        margin-top: -2px;
                        margin-left: -1px;
                        font-size: $--font-size-small;
                        font-weight: $strong;
                    }
                }
            }
        }
        .import-loader {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            background-color: rgba(255,255,255, 0.85);
        }
    }
</style>
