<template>
  <header
    v-if="businessSource && businessSource.client_id && businessSource.synced_at === null && !isForceHideHeader && isAppfolio"
    id="integration-confirmed-header"
  >
    <AppfolioSyncDialog
      v-if="uiFlags.isDialogVisible"
      @close="handleClose"
    />
    <div class="font-17 font-weight-bold">
      <div
        class="bg-green rounded-circle d-inline-flex justify-content-center align-items-center"
        style="width: 21px; height: 21px"
      >
        <i class="sdicon-check text-white font-17" />
      </div>
      AppFolio Integration Confirmed
    </div>
    <div>
      you can now activate the integration to sync your data
    </div>
    <ElButton
      type="primary"
      size="medium"
      @click="uiFlags.isDialogVisible = true"
    >
      Activate Integration
    </ElButton>
  </header>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import AppfolioSyncDialog from '@/components/property/AppfolioSyncDialog.vue';
import { BusinessSourceTypes } from '@/constants/BusinessSource';

export default {
  name: 'IntegrationConfirmedHeader',
  components: {
    AppfolioSyncDialog,
  },
  setup(props, context) {
    const { $store } = context.root;
    const uiFlags = reactive({
      isDialogVisible: false,
    });
    const businessSource = computed(() => $store.getters['BusinessSource/get']);
    const isForceHideHeader = ref(false);
    const isAppfolio = ref(businessSource.value?.source_type === BusinessSourceTypes.APPFOLIO);

    return {
      uiFlags,
      businessSource,
      handleClose,
      isForceHideHeader,
      isAppfolio,
    };

    function handleClose() {
      isForceHideHeader.value = true;
      uiFlags.isDialogVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
#integration-confirmed-header {
  width: 100%;
  padding: 1.5rem 0;
  background: theme-color('primary-lighter');
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
