<template>
  <div
    v-click-outside="handleClickOutside"
    class="help-center"
    :class="isRouteWithBottomActionBar ? 'help-center-fixed-to-action-bar' : ''"
  >
    <transition
      v-if="isHelpCenterDialogShown"
      name="fade"
    >
      <div class="help-center-dialog">
        <div
          v-for="(helpMenuDefinition, index) in helpMenuDefinitions"
          :key="`help-def-${index}`"
          :class="[
            helpMenuDefinition.name === 'separator' ? 'help-center-separator' : 'help-center-item',
            helpMenuDefinition.isFrontChat && !isFrontInitilized ? 'help-center-item-disabled' : '',
            helpMenuDefinition.isFrontChat && !user ? 'help-center-chat-hidden' : ''
          ]"
          @click="helpMenuDefinition.action"
        >
          <div
            v-if="helpMenuDefinition.icon"
            class="link-icon"
          >
            <i :class="helpMenuDefinition.icon" />&nbsp;
          </div>
          <template v-if="helpMenuDefinition.name !== 'separator'">
            {{ helpMenuDefinition.name }}
          </template>
        </div>
      </div>
    </transition>
    <div
      class="icon"
      @click="isHelpCenterDialogShown = !isHelpCenterDialogShown"
    >
      ?
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';

const isProd = process.env.VUE_APP_ENV === 'production';
const helpMenuDefinitions = [
  {
    name: 'Help & Documentation',
    action: () => window.open('https://help.showdigs.com', '_blank'),
    icon: 'sdicon-book-open',
  },
  {
    name: `Message Support ${!isProd ? '(Prod Only)' : ''}`,
    isFrontChat: true,
    action: () => window.FrontChat('show'),
    icon: 'sdicon-chat-bubble',
  },
  {
    name: 'Contact Us',
    action: () => window.open('mailto:support@showdigs.com'),
    icon: 'sdicon-mail',
  },
  {
    name: 'separator',
    action: () => { },
  },
  {
    name: 'What\'s New',
    action: () => window.open('https://showdigs.notion.site/PM-Changelog-65b96592204a4e458a3598c2d539265c?pvs=4', '_blank'),
  },
  {
    name: 'separator',
    action: () => { },
  },
  {
    name: 'Terms & Conditions',
    action: () => window.open('https://www.showdigs.com/terms-of-use', '_blank'),
  },
  {
    name: 'Privacy Policy',
    action: () => window.open('https://www.showdigs.com/privacy', '_blank'),
  },
  {
    name: 'Status',
    action: () => window.open('https://stats.uptimerobot.com/M7ywWF5xVy', '_blank'),
  },
];

export default {
  name: 'HelpCenter',
  props: {
    isMobile: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const isHelpCenterDialogShown = ref(false);
    const isFrontInitilized = ref(false);
    const isRouteWithBottomActionBar = computed(() => ['app.properties', 'app.units', 'app.units.show', 'app.condition-reports'].includes(context.root.$route.name));

    const state = context.root.$store.state;
    init();

    return {
      user: state.Auth.user,
      isHelpCenterDialogShown,
      isRouteWithBottomActionBar,
      helpMenuDefinitions,
      handleClickOutside,
      isFrontInitilized,
    };

    function handleClickOutside(e) {
      if (isHelpCenterDialogShown.value) {
        isHelpCenterDialogShown.value = false;
      }
    }

    function init() {
      const { user = {} } = state.Auth;
      if (isProd && window.FrontChat && user.front_user_hash) {
        window.FrontChat('init', {
          useDefaultLauncher: false,
          chatId: '4f94cbe5153cf04470695ebd73605e8c',
          email: user.email,
          name: user.full_name,
          userHash: user.front_user_hash,
          customFields: {
            user_id: user.id,
            business_id: user.business_id,
          },
        });
        isFrontInitilized.value = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.help-center {
  position: fixed;
  bottom: 120px;
  right: 25px;
  z-index: 9999;
}

.help-center-dialog {
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  background-color: $white;
}

.help-center-item {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(gray-color('light'), 0.5);
  }
}

.help-center-chat-hidden {
  display: none;
}

.help-center-item-disabled {
  color: gray-color();
  pointer-events: default;

  &:hover,
  &:focus {
    background-color: white;
  }
}

.help-center-separator {
  margin: 10px 0;
  border-bottom: 1px solid gray-color();
}

.link-icon {
  color: gray-color('dark');
  position: relative;
  margin-left: -4px;
  margin-right: 4px;
  width: 22px;
  height: 22px;
}

.icon {
  border: 2px solid gray-color('light');
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  right: inherit;
  bottom: 70px;
  font-size: 28px;
  color: gray-color('dark');
  background: $white;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

  &:hover {
    background: gray-color('lighter');
    box-shadow: 0 4px 20px rgba(theme-color('primary'), 0.25);
    color: theme-color('primary')
  }
}

@include media-breakpoint-down(md) {
  .help-center {
    bottom: 65px;
    right: 15px;
  }

  .help-center-fixed-to-action-bar {
    right: 75px;
  }

  .icon {
    bottom: 15px;
  }
}
</style>
