<template>
  <div
    id="app-layout"
    class="layout"
    :class="[$viewport.lt.md ? 'mobile-layout' : 'desktop-layout',appBackgroundColor]"
  >
    <SdImpersonationHeader v-if="$store.state.Auth.imposter" />
    <SdAccountInactiveHeader />
    <IntegrationConfirmedHeader />
    <SdHeader
      image="logo"
      bottom-border
    />

    <div class="outer-container flex-column">
      <PortalTarget name="container-top" />

      <div :class="containerClass">
        <router-view :key="$route.fullPath" />
      </div>

      <HelpCenter />
    </div>
    <SdFooter v-show="$viewport.gt.md" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { PortalTarget } from 'portal-vue';
import IntegrationConfirmedHeader from '@/components/settings/IntegrationConfirmedHeader.vue';
import HelpCenter from '@/components/common/HelpCenter';

export default {
  components: { IntegrationConfirmedHeader, PortalTarget, HelpCenter },
  setup(props, context) {
    const appBackgroundColor = computed(() => (context.root.$route.meta.whiteBackground
      ? 'white-app-layout' : ''));

    const containerClass = computed(() => (context.root.$route.meta.fullWidthContainer
      ? 'full-width-container container' : 'container'));

    return { appBackgroundColor, containerClass };
  },
};
</script>

<style lang="scss">
#app-layout {
    background-color: $app-background-color;
}
    .white-app-layout{
        background-color: $--color-white !important;
    }
    .desktop-layout .full-width-container{
        max-width: -webkit-fill-available;
        max-width: -moz-available;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
</style>
